/**
 * Copyright Visionoid LLP
 */

export const Routes = {
    Default:"/",
    Home:"/home",
    About:"/about",
    Blog:"/blog",
    GetInTouch:"/get-in-touch",
    Projects:"/projects",
    NotFound:"/not-found"
}