/**
 * Copyright Visionoid LLP
 */

import React from "react";

export const Home: React.FC = () => {
  return (
    <>
      <header className="App-header">
        Dear Visitor,
        <p></p>
        <p>
          <code>
            Ranjan Nayak's portfolio creation has just begun, we'll be live
            soon.
          </code>
        </p>
      </header>
    </>
  );
};
