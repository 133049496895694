/**
 * Copyright Visionoid LLP
 */

import React from "react";
import "./styles/App.css";
import { IndexPage } from "./pages";

const App: React.FC = () => {
  return <IndexPage />;
};

export default App;
