/**
 * Copyright Visionoid LLP
 */

import { Flex, Segment, Text } from "@fluentui/react-northstar";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { About } from "./app-pages/About";
import { Blog } from "./app-pages/Blog";
import { GetInTouch } from "./app-pages/GetInTouch";
import { Home } from "./app-pages/Home";
import { NotFound } from "./app-pages/NotFound";
import { Projects } from "./app-pages/Projects";
import { Routes } from "../Routes";
// import { About, Blog, GetInTouch, Home, NotFound, Projects } from "./app-pages";

interface IPage {
  displayName?: string;
  route: string;
  component: React.FC;
  exact?: boolean;
}

const pages: IPage[] = [
  { displayName: "Home", route: Routes.Home, component: Home, exact: false },
  { displayName: "About", route: Routes.About, component: About, exact: true },
  {
    displayName: "Projects",
    route: Routes.Projects,
    component: Projects,
    exact: true,
  },
  { displayName: "Blog", route: Routes.Blog, component: Blog, exact: true },
  {
    displayName: "Get in touch",
    route: Routes.GetInTouch,
    component: GetInTouch,
    exact: true,
  },
];

export const IndexPage: React.FC<any> = () => {
  const PageLinks = () => {
    return (
      <Flex>
        {pages.map((page: IPage, index: number) => {
          return (
            <>
              <Text
                as={Link}
                to={page.route}
                style={{
                  textDecoration: "none",
                  color: "white",
                  padding: 4,
                }}
                className="LinkText"
                key={index}
                content={page.displayName}
              />
            </>
          );
        })}
      </Flex>
    );
  };

  const PageRoutes = () => {
    return (
      <Switch>
        {pages.map((page: IPage, index: number) => {
          return (
            <Route
              key={index}
              exact={page.exact}
              path={page.route}
              component={page.component}
            />
          );
        })}
        <Redirect exact path="/" to="/home" />
        <Route component={NotFound} />
      </Switch>
    );
  };

  return (
    <Flex className="App">
      <Segment key="links" content={PageLinks()} />
      <Segment key="routes" content={PageRoutes()} />
    </Flex>
  );
};
